import React from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import EsgTrainingFormBuilder from "../formBuilder/esg-training-form-builder"

export default function EsgTraining() {
  const handleSubmit = async register => {
    let payload
    console.log("register===", register)
    payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",

        data: {
            company_name: register.company_name,
            designation:"no required",
            email:register.email,
            country:"not required",
            form_type:"esg-training",
            address: register.address,
            full_name: register.full_name,
            phone_number: register.phone_number,
            employees_to_be_trained: register.employees_to_be_trained,
            duration_of_training: register.duration_of_training,
            remarks: register.remarks,
        },
      },
    }
    try {
      const result = await axios.post(
        `${process.env.URL}/api/user-interests`,
        payload
      )

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">ESG Training in Corporates</h1>
        <ToastContainer />

        <Form
          formBuilder={EsgTrainingFormBuilder}
          className="ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}

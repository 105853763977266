import { useState, useEffect } from "react"
import Countries from "../pages/countries.json"
import * as yup from "yup"

function EsgTrainingFormBuilder(values) {
  const fileSchema = yup.array().of(yup.object()).nullable()
  const [schema, setSchema] = useState({})

  useEffect(() => {
    const schema = {
        company_name: {
        label: "Name of organization",
        type: "text",
        required: true,
      },
      address: {
        label: "Address of organization",
        type: "text",
        required: true,
      },
      full_name: {
        label: "Contact Person",
        type: "text",
        required: true,
      },
      phone_number: {
        label: "Contact Number ",
        type: "text",
        required: true,
      },
      email: {
        label: "Contact Email ",
        type: "text",
        required: true,
      },
      employees_to_be_trained: {
        label: "No of employees to be trained.",
        type: "text",
        required: true,
      },
      duration_of_training: {
        label: "Duration of training",
        type: "text",
        required: true,
      },
      remarks: {
        label: "Any specific requirements for Training",
        type: "text",
        required: true,
      },
    }

    setSchema(schema)
  }, [values])

  return schema
}

export default EsgTrainingFormBuilder
